import React, {useEffect, useReducer} from 'react';
import {Badge, Button, Card, Col, Form, Modal, OverlayTrigger, Row, Table, Tooltip} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faCheck, faEye, faPenToSquare, faPlus, faPowerOff, faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import API from '../services/API';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const hover = ['hover'];
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editModalMode, setEditModalMode] = React.useState(false);
  const [currentFieldId, setCurrentFieldId] = React.useState(false);
  const [currentFieldName, setCurrentFieldName] = React.useState('');
  const [fields, setFields] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {});

  const refreshFields = () => {
    API.getBookingFields(history).then(response => {
      if (response && response.data.success === true) {
        const fields = response.data.data.fields.sort(function (a, b) {
          return a.position - b.position
        });
        setFields(fields);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshFields();
  }, []);

  const handleAddOrEdit = (e) => {
    e.preventDefault();
    if (editModalMode) {
      API.putFields(history, formData, currentFieldId).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          setShowModal(false);
          refreshFields();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.postBookingFields(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'FIELD_ADDED'}));
          setShowModal(false);
          refreshFields();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleChange = event => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData({
      name: event.target.id,
      value: isCheckbox ? event.target.checked : event.target.value
    })
  }

  const handleNewFieldButton = () => {
    setFormData({});
    setEditModalMode(false);
    setShowModal(true);
  }

  const handleEditFieldButton = (props) => {
    setFormData(props);
    setCurrentFieldId(props.id);
    setEditModalMode(true);
    setShowModal(true);
  }

  const handleEnableOrDisableButton = (props) => {
    if (props.active) {
      API.getDisableField(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'FIELD_DISABLED'}));
          refreshFields();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.getEnableField(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'FIELD_ENABLED'}));
          refreshFields();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleDeleteFieldButton = (props) => {
    setCurrentFieldId(props.id);
    setCurrentFieldName(props.name1 + (props.name2 ? ' - ' + props.name2 : ''));
    setShowDeleteModal(true);
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // update position variable
    let index = 0;
    for (const [key, value] of Object.entries(result)) {
      value.position = index++;
    }

    return result;
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      fields,
      result.source.index,
      result.destination.index
    );

    setFields(items);

    // Prepare data for backend
    let data = {
      fields: []
    };

    for (const [key, value] of Object.entries(items)) {
      data.fields.push({
        id: value.id,
        position: value.position
      });
    }

    API.postReorderFields(history, data).then(response => {
      if (response && response.data.success === true) {
        // Everything is OK
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const deleteField = () => {
    API.deleteFields(history, currentFieldId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'FIELD_DELETED'}));
        setShowDeleteModal(false);
        refreshFields();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const TableRow = (props) => {
    const {key, provided, intl, name1, name2, defaultField, required1, required2, active} = props;

    return (
      <tr key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <td className="td-center">
          <FontAwesomeIcon icon={faBars}/>
        </td>
        <td className="td-center">
          {defaultField ? intl.formatMessage({id: name1}) : name1}
          <br/>
          {required1 && <Badge bg="primary" className="me-1"><FormattedMessage id="REQUIRED"/></Badge>}
        </td>
        <td className="td-center">
          {name2 && defaultField ? intl.formatMessage({id: name2}) : name2}
          <br/>
          {required2 && <Badge bg="primary" className="me-1"><FormattedMessage id="REQUIRED"/></Badge>}
        </td>
        <td className="td-center">
          {active ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
        </td>
        <td className="td-center">
          {!defaultField && <OverlayTrigger trigger={hover}
                                            overlay={
                                              <Tooltip><FormattedMessage id="EDIT"/></Tooltip>
                                            }>
            <Button style={{marginRight: '5px'}} variant="light" onClick={() => handleEditFieldButton(props)}>
              <FontAwesomeIcon icon={faPenToSquare}/>
            </Button>
          </OverlayTrigger>}
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip>{active ? <FormattedMessage id="DISABLE"/> : <FormattedMessage id="ENABLE"/>}</Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="warning" onClick={() => handleEnableOrDisableButton(props)}>
              <FontAwesomeIcon icon={faPowerOff}/>
            </Button>
          </OverlayTrigger>
          {!defaultField && <OverlayTrigger trigger={hover}
                                            overlay={
                                              <Tooltip><FormattedMessage id="DELETE"/></Tooltip>
                                            }>
            <Button variant="danger" onClick={() => handleDeleteFieldButton(props)}>
              <FontAwesomeIcon icon={faTrashCan}/>
            </Button>
          </OverlayTrigger>}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={handleNewFieldButton}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_FIELD"/></span>
      </Button>
      <Button variant="primary" className="text-white me-2" onClick={() => window.open('/#/booking?id=preview', '_blank')}>
        <FontAwesomeIcon icon={faEye} className="me-2"/>
        <span><FormattedMessage id="PREVIEW"/></span>
      </Button>
      <br/><br/>
      {(fields && fields.length > 0) &&
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
              <tr>
                <th className="border-0 text-center"></th>
                <th className="border-0 text-center"><FormattedMessage id="COLUMN"/> 1</th>
                <th className="border-0 text-center"><FormattedMessage id="COLUMN"/> 2</th>
                <th className="border-0 text-center"><FormattedMessage id="ACTIVE_TAB"/></th>
                <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
              </tr>
              </thead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="table-body">
                  {(provided, snapshot) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {fields && fields.map((data, index) => {
                        return (
                          <Draggable
                            draggableId={data.position.toString()}
                            key={data.position.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TableRow key={`field-${data.position}`} provided={provided} intl={intl} {...data} />
                            )}
                          </Draggable>
                        )
                      }
                    )}
                    {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </Card.Body>
        </Card>
      }
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6">{editModalMode ? <FormattedMessage id="EDIT_FIELD"/> : <FormattedMessage id="NEW_FIELD"/>}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="add-field-form" onSubmit={handleAddOrEdit}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="FIELD_NAME"/> 1</Form.Label>
                  <Form.Control required type="text" id="name1" maxLength="255"
                                onChange={handleChange} value={formData.name1}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label></Form.Label>
                <br/><br/>
                <Form.Check label={intl.formatMessage({id: 'REQUIRED_FIELD'})} id="required1"
                            onChange={handleChange}
                            checked={formData.required1 || false}/>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="FIELD_NAME"/> 2</Form.Label>
                  <Form.Control type="text" id="name2" maxLength="255"
                                onChange={handleChange} value={formData.name2}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label></Form.Label>
                <br/><br/>
                <Form.Check label={intl.formatMessage({id: 'REQUIRED_FIELD'})} id="required2"
                            onChange={handleChange}
                            checked={formData.required2 || false}/>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="add-field-form">
            {editModalMode ? <FormattedMessage id="RECORD"/> : <FormattedMessage id="ADD"/>}
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="DELETE_FIELD" values={{name: currentFieldName}}/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteField}>
            <FormattedMessage id="DELETE"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
