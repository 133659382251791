import React from 'react';
import {Accordion} from '@themesberg/react-bootstrap';
import AccordionItemComponent from './AccordionItemComponent';

export default (props) => {
  const {defaultKey, data = [], className = ''} = props;

  return (
    <Accordion className={className} defaultActiveKey={defaultKey}>
      {data.map(d => <AccordionItemComponent key={`accordion-${d.id}`} {...d} />)}
    </Accordion>
  );
};