import React from 'react';
import {Accordion, Card} from '@themesberg/react-bootstrap';

export default (props) => {
  const {eventKey, title, content, show} = props;

  if (show !== undefined && show === false) {
    return <></>
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Button variant="link" className="w-100 d-flex justify-content-between">
          <span className="h6 mb-0 fw-bold">
            {title}
          </span>
      </Accordion.Button>
      <Accordion.Body style={{background: 'white'}}>
        <Card.Body className="py-2 px-0">
          {content}
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item>
  );
};