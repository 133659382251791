import React, {createContext, useContext, useState} from 'react';
import {createIntl, createIntlCache} from 'react-intl';
import messages_fr from '../locales/fr.json';
import messages_en from '../locales/en.json';
import messages_es from '../locales/es.json';
import Utils from './Utils';

const messages = {
  'fr': messages_fr,
  'en': messages_en,
  'es': messages_es
};

// Créez un cache pour éviter les problèmes de performance
const cache = createIntlCache();

// Créez le Contexte pour la langue
const IntlContext = createContext();

// Fournisseur du Contexte
export const IntlProvider = ({children}) => {
  const [locale, setLocale] = useState(Utils.getLanguage() || 'fr'); // Définir la langue par défaut
  const [intl, setIntl] = useState(() =>
    createIntl({locale, messages: messages[locale], defaultLocale: 'fr'}, cache)
  );
  const [key, setKey] = useState(0); // Clé pour forcer un re-render

  // Fonction pour changer dynamiquement de langue
  const switchLanguage = (lang) => {
    console.log('switchLanguage:' + lang);
    setLocale(lang);
    setIntl(createIntl({locale: lang, messages: messages[lang], defaultLocale: 'fr'}, cache));
  };

  return (
    <IntlContext.Provider value={{intl, locale, switchLanguage}}>
      {children}
    </IntlContext.Provider>
  );
};

// Hook pour utiliser le Contexte plus facilement
export const useIntl = () => useContext(IntlContext);