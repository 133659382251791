import React, {useEffect, useReducer} from 'react';
import {Button, Col, Container, Form, Nav, OverlayTrigger, Row, Tab, Tooltip} from '@themesberg/react-bootstrap';
import {Map, Marker, TileLayer, useLeaflet} from 'react-leaflet';
import API from '../../services/API';
import Utils from '../../services/Utils';
import queryString from 'query-string';
import FieldRow from '../../components/FieldRow';
import {useHistory} from 'react-router-dom';

export default (props) => {

  const ResizeMap = () => {
    const leaflet = useLeaflet();
    leaflet.map.invalidateSize();
    return null;
  };

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl, switchLanguage} = props;
  const history = useHistory();
  const hover = ['hover'];
  const [loading, setLoading] = React.useState(false);
  const [booked, setBooked] = React.useState(false); // (iOS only)
  const [paymentUrl, setPaymentUrl] = React.useState(''); // (iOS only)
  const [formData, setFormData] = useReducer(formReducer, {});
  const [bookingInfos, setBookingInfos] = React.useState({});
  const [paymentMode, setPaymentMode] = React.useState('DRIVER');
  const [validDiscount, setValidDiscount] = React.useState(undefined);
  const [discountPrice, setDiscountPrice] = React.useState(undefined);
  const [discountMessage, setDiscountMessage] = React.useState('');
  const params = queryString.parse(props.location.search);
  const id = params.id;
  const lang = params.lang;
  const [formatter, setFormatter] = React.useState(new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'}));

  useEffect(() => {
    if (lang && lang !== intl.locale) {
      switchLanguage(lang);
    }
  }, [lang, intl.locale, switchLanguage]);

  useEffect(() => {
    if (id) {
      if (id === 'preview') {
        if (!Utils.isAccessToken(history)) {
          return;
        }
        API.getBookingPreview(history).then(response => {
          if (response && response.data.success === true) {
            setBookingInfos(response.data.data);
            if (!(response.data.data.driverPayments === true && response.data.data.onlinePayments === true)) {
              setPaymentMode(response.data.data.onlinePayments === true ? 'ONLINE' : 'DRIVER');
            }
          } else {
            Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
          }
        });
      } else {
        API.getBooking(id).then(response => {
          if (response && response.data.success === true) {
            setBookingInfos(response.data.data);
            if (!(response.data.data.driverPayments === true && response.data.data.onlinePayments === true)) {
              setPaymentMode(response.data.data.onlinePayments === true ? 'ONLINE' : 'DRIVER');
            }
            setFormatter(new Intl.NumberFormat(navigator.language, {
              style: 'currency',
              currency: response.data.data.currency,
            }));
          } else if (response && response.data.success === false && response.data.data) {
            window.location.replace(response.data.data);
          } else {
            Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
          }
        });
      }
    }
  }, [intl]);

  const handleChange = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleApplyDiscount = (e) => {
    if (id === 'preview') {
      return;
    }
    API.getDiscount(id, formData.discountCode).then(response => {
      if (response && response.data.success === true) {
        if (response.data.data.discount) {
          setValidDiscount(true);
          setDiscountPrice(response.data.data.discountPrice);

          const fixedDiscount = response.data.data.fixedDiscount;
          const percentageDiscount = response.data.data.percentageDiscount;
          if (fixedDiscount && percentageDiscount) {
            setDiscountMessage(intl.formatMessage({id: 'DISCOUNT_MESSAGE'}, {discount: (percentageDiscount + ' %') + ' + ' + formatter.format(fixedDiscount)}));
          } else {
            setDiscountMessage(intl.formatMessage({id: 'DISCOUNT_MESSAGE'}, {discount: (fixedDiscount ? formatter.format(fixedDiscount) : percentageDiscount + ' %')}));
          }
        } else {
          setValidDiscount(false);
        }
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (id === 'preview') {
      return;
    }

    if (paymentUrl) {
      window.open(paymentUrl, '_blank');
    } else {
      formData.paymentMode = paymentMode;
      formData.consent = intl.formatMessage({id: 'RGPD'});

      if (!formData.country) {
        formData.country = intl.locale === 'fr' ? 'FR' : 'US';
      }

      setLoading(true);
      API.postCheckout(formData, id).then(response => {
        if (response) {
          const isIos = /iPad|iPhone|iPod/.test(navigator.platform);
          if (paymentMode === 'ONLINE') {
            if (isIos) {
              setLoading(false);
              setPaymentUrl(response.data);
            } else {
              window.location.replace(response.data);
            }
          } else if (paymentMode === 'DRIVER') {
            alert(intl.formatMessage({id: 'ALERT_BOOKING_MADE'}));
            if (isIos) {
              setBooked(true);
            } else {
              window.location.replace(bookingInfos.redirectionUrl ? bookingInfos.redirectionUrl : bookingInfos.url);
            }
          }
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
          setLoading(false);
        }
      });
    }
  }

  const getArrayCoords = (coords) => {
    const split = coords.split(',');
    const arrayCoords = [];
    arrayCoords.push(split[0]);
    arrayCoords.push(split[1]);
    return arrayCoords;
  }

  const getNullableArrayCoords = (coords) => {
    if (coords) {
      const split = coords.split(',');
      const arrayCoords = [];
      arrayCoords.push(split[0]);
      arrayCoords.push(split[1]);
      return arrayCoords;
    }
    return [46.41, 2.05];
  }

  return (
    <main>
      {id &&
        <Row>
          {!booked && <Col md={6}>
            <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
              <Container>
                <Row>
                  <Col xs={12} className="d-flex align-items-center justify-content-end">
                    <div className="bg-white border rounded border-light p-4 p-lg-5 w-100 fmxw-500" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                      <div>
                        <div className="text-center text-md-center mb-4 mt-md-0">
                          <h3 className="mb-0">{intl.formatMessage({id: 'RESERVATION'})}</h3>
                        </div>
                        <Form className="mt-4" onSubmit={handleSubmit}>
                          {bookingInfos.fields && bookingInfos.fields.map((data, index) => <FieldRow key={index} intl={intl} handleChange={handleChange} validDiscount={validDiscount}
                                                                                                     formData={formData} bookingInfos={bookingInfos} handleApplyDiscount={handleApplyDiscount}
                                                                                                     {...data} />)}
                          <br/>
                          {(bookingInfos.driverPayments && bookingInfos.onlinePayments) &&
                            <Tab.Container defaultActiveKey="DRIVER">
                              <Nav fill variant="pills" className="flex-column flex-sm-row">
                                <Nav.Item>
                                  <OverlayTrigger trigger={hover}
                                                  overlay={
                                                    <Tooltip>{intl.formatMessage({id: 'TOOLTIP_DRIVER_PAYMENT'})}</Tooltip>
                                                  }>
                                    <Nav.Link eventKey="DRIVER" className="mb-sm-3 mb-md-0" onSelect={(eventKey) => setPaymentMode(eventKey)}>
                                      {intl.formatMessage({id: 'DRIVER_PAYMENT'})}
                                    </Nav.Link>
                                  </OverlayTrigger>
                                </Nav.Item>
                                <Nav.Item>
                                  <OverlayTrigger trigger={hover}
                                                  overlay={
                                                    <Tooltip>{intl.formatMessage({id: 'TOOLTIP_ONLINE_PAYMENT'})}</Tooltip>
                                                  }>
                                    <Nav.Link eventKey="ONLINE" className="mb-sm-3 mb-md-0" onSelect={(eventKey) => setPaymentMode(eventKey)}>
                                      {intl.formatMessage({id: 'ONLINE_PAYMENT'})}
                                    </Nav.Link>
                                  </OverlayTrigger>
                                </Nav.Item>
                              </Nav>
                            </Tab.Container>
                          }
                          <Form.Check required label={intl.formatMessage({id: 'RGPD'})} id="checkboxRGPD"/>
                          <br/>
                          <Button variant="primary" type="submit" className="w-100 btn-login" disabled={loading}>
                            {loading && (
                              <i className="spinner-border spinner-border-sm text-light"/>
                            )}
                            {(!loading && paymentMode === 'DRIVER') &&
                              <span>{intl.formatMessage({id: 'CONFIRM_BOOKING'})}</span>
                            }
                            {(!loading && paymentMode === 'ONLINE' && !paymentUrl) &&
                              <span>{intl.formatMessage({id: 'GO_TO_PAYMENT'})}</span>
                          }
                          {(!loading && paymentMode === 'ONLINE' && paymentUrl) &&
                            <span>{intl.formatMessage({id: 'IOS_ONLINE_PAYMENT'})}</span>
                            }
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </Col>}
          <Col md={6}>
            <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
              <Container>
                <Row>
                  <Col xs={12} className="d-flex align-items-center">
                    <div className="bg-white border rounded border-light p-4 p-lg-5 w-100 fmxw-500" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                      <div>
                        <div className="text-center text-md-center mb-4 mt-md-0">
                          {!booked && <h3 className="mb-0">{intl.formatMessage({id: 'JOURNEY'})}</h3>}
                          {booked && <h3 className="mb-0">{intl.formatMessage({id: 'BOOKING_MADE'})}</h3>}
                        </div>
                        <Map center={getNullableArrayCoords(bookingInfos.startingCoords)} zoom={7} scrollWheelZoom={true} style={{width: '100%', height: '300px'}}>
                          <ResizeMap/>
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          {bookingInfos.startingCoords && <Marker position={getArrayCoords(bookingInfos.startingCoords)}/>}
                          {bookingInfos.arrivalCoords && <Marker position={getArrayCoords(bookingInfos.arrivalCoords)}/>}
                        </Map>
                        <br/>
                        <b>{intl.formatMessage({id: 'STARTING_ADDRESS'})}:</b> {bookingInfos.startingAddress}<br/>
                        <b>{intl.formatMessage({id: 'ARRIVAL_ADDRESS'})}:</b> {bookingInfos.arrivalAddress}<br/>
                        <b>{intl.formatMessage({id: 'VEHICLE'})}:</b> {bookingInfos.vehicle}<br/>
                        {bookingInfos.passengers > 0 &&
                          <span>
                            <b>{intl.formatMessage({id: 'PASSENGERS'})}:</b> {bookingInfos.passengers}<br/>
                            <b>{intl.formatMessage({id: 'BAGGAGE'})}:</b> {bookingInfos.baggage}<br/>
                          </span>
                        }
                        <b>
                          {intl.formatMessage({id: 'ONE_WAY_OR_ROUND_TRIP'})}:</b> {bookingInfos.route === 'ROUND_TRIP' ? intl.formatMessage({id: 'ROUND_TRIP'}) : intl.formatMessage({id: 'ONE_WAY'})}<br/>
                        <b>
                          {!bookingInfos.returnPickUpTime &&
                            <span>{intl.formatMessage({id: 'DATES_AND_TIMES'})}</span>
                          }
                          {bookingInfos.returnPickUpTime &&
                            <span>{intl.formatMessage({id: 'ONE_WAY_DATES_AND_TIMES'})}</span>
                          }
                          :</b> {bookingInfos.pickUpTime}<br/>
                        {bookingInfos.returnPickUpTime &&
                          <span><b>{intl.formatMessage({id: 'RETURN_DATES_AND_TIMES'})}:</b> {bookingInfos.returnPickUpTime}<br/></span>
                        }
                        <b>{intl.formatMessage({id: 'TRAVEL_TIME'})}:</b> {bookingInfos.durationMinutes} minutes<br/>
                        <b>{intl.formatMessage({id: 'DISTANCE'})}:</b> {bookingInfos.distance} {bookingInfos.lengthUnit}<br/>
                        {bookingInfos.options && bookingInfos.options.map(o => <span><b>{o.name}:</b> {o.quantity}<br/></span>)}
                        <br/>
                        <h3 className="text-center mb-0" style={validDiscount ? {
                          textDecoration: 'line-through',
                          textDecorationThickness: '2px',
                          color: '#4A5073',
                          opacity: 0.75
                        } : {color: '#4A5073'}}>{formatter.format(bookingInfos.price)}</h3>
                        {validDiscount && <h3 className="text-center mb-0" style={{color: '#4A5073'}}>{formatter.format(discountPrice)}</h3>}
                        {validDiscount && <p className="text-center" style={{color: '#05A677'}}><b>{discountMessage}</b></p>}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </Col>
        </Row>}
    </main>
  );
};