export const Routes = {

  Root: {path: '/'},
  DashboardOverview: {path: '/admin/dashboard'},
  Settings: {path: '/admin/settings'},
  Vehicles: {path: '/admin/vehicles'},
  Packages: {path: '/admin/packages'},
  Surcharges: {path: '/admin/surcharges'},
  Options: {path: '/admin/options'},
  DiscountCodes: {path: '/admin/discount'},
  BookingFields: {path: '/admin/booking-fields'},
  HtmlIntegration: {path: '/admin/html-integration'},
  Signin: {path: '/auth/sign-in'},
  ForgotPassword: {path: '/auth/forgot-password'},
  ResetPassword: {path: '/auth/reset-password'},
  NotFound: {path: '/error/404'},
  Booking: {path: '/booking'},
  PaymentSuccess: {path: '/booking/success'},
  PaymentCancel: {path: '/booking/cancel'},
  BookingConfirmed: {path: '/booking/confirm'},
  BookingCanceled: {path: '/booking/canceled'},
  MonthlySubscription: {path: '/subscription/monthly'},
  AnnualSubscription: {path: '/subscription/annual'},
  LifetimeSubscription: {path: '/subscription/lifetime'},
  SubscriptionSuccess: {path: '/subscription/success'},
  LifetimeSuccess: {path: '/subscription/success-lifetime'},

};