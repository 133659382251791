import {Store} from 'react-notifications-component';
import {ACCESS_TOKEN, EMAIL, LANGUAGE, NAME} from './constants';
import {faEuro, faDollar, faPoundSign, faYen, faFrancSign, faBahtSign, faMoneyBill} from '@fortawesome/free-solid-svg-icons';

export default {
  logout: (history) => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(EMAIL);
    localStorage.removeItem(NAME);
    history.push('/');
  },
  isAccessToken: (history, noPush) => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      if (!noPush) {
        history.push('/');
      }
      return false;
    }
    return true;
  },
  checkAccessTokenForLogin: (history) => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      history.push('/admin/dashboard');
    }
  },
  setBackground: (isColoredBackground) => {
    if (isColoredBackground === true) {
      document.body.setAttribute('style', 'background: #000034 !important');
    } else {
      document.body.setAttribute('style', 'background: #F5F8FB');
    }
  },
  notify: (title, message) => {
    Store.addNotification({
      title: title,
      message: message,
      type: 'default',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  },
  notifySuccess: (title, message) => {
    Store.addNotification({
      title: title,
      message: message,
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  },
  notifyError: (title, message) => {
    Store.addNotification({
      title: title,
      message: message,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  },
  notifyWarning: (title, message) => {
    Store.addNotification({
      title: title,
      message: message,
      type: 'warning',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  },
  getCurrencyIcon: (currency) => {
    switch (currency) {
      case 'EUR':
        return faEuro;
      case 'AUD':
      case 'CAD':
      case 'USD':
      case 'HKD':
      case 'NZD':
      case 'SGD':
      case 'MXN':
        return faDollar;
      case 'GBP':
      case 'GIP':
        return faPoundSign;
      case 'JPY':
        return faYen;
      case 'CHF':
        return faFrancSign;
      case 'THB':
        return faBahtSign;
      default:
        return faMoneyBill;
    }
  },
  getLanguage: () => {
    return localStorage.getItem(LANGUAGE) ? localStorage.getItem(LANGUAGE) : navigator.language.split(/[-_]/)[0]; // Language without region code
  }
};