import React, {memo} from 'react';
import {Button, Col, Form, Row} from '@themesberg/react-bootstrap';
import Countries from './Countries';

const FieldRow = memo((props) => {
  let {intl, handleChange, validDiscount, formData, bookingInfos, handleApplyDiscount, id, name1, name2, required1, required2} = props;
  let isDefaultField;
  let type = 'text';
  let variable1 = convertDefaultFieldNameToVariableName(name1);
  let variable2 = convertDefaultFieldNameToVariableName(name2);

  switch (name1) {
    case 'FIRST_NAME':
    case 'LAST_NAME':
    case 'COMPANY_NAME':
    case 'STREET_ADDRESS':
    case 'APARTMENT_OFFICE_ETC':
    case 'CITY':
    case 'ORDER_NOTES':
    case 'FLIGHT_OR_TRAIN_NUMBER':
      isDefaultField = true;
      type = 'text';
      break;
    case 'EMAIL_ADDRESS':
      isDefaultField = true;
      type = 'email';
      break;
    case 'PHONE':
      isDefaultField = true;
      type = 'tel';
      break;
    case 'ZIP_CODE':
      isDefaultField = true;
      type = 'number';
      break;
    case 'COUNTRY':
    case 'BOOKING_DISCOUNT_CODE':
      isDefaultField = true;
      break;
  }

  if (name1 === 'COUNTRY') {
    return <Row>
      <Col md={12} className="mb-3">
        <Form.Group>
          <Form.Label>{intl.formatMessage({id: 'COUNTRY'})}</Form.Label>
          <Countries id="country" onChange={handleChange} value={formData.country} intl={intl}/>
        </Form.Group>
      </Col>
    </Row>
  } else if (name1 === 'ORDER_NOTES') {
    return <Row>
      <Col md={12}>
        <Form.Group key="notes" className="mb-2">
          <label>{intl.formatMessage({id: 'ORDER_NOTES'})} ({intl.formatMessage({id: 'OPTIONAL'})})</label>
          <Form.Control as="textarea" rows="3" id="notes" placeholder={intl.formatMessage({id: 'PLACEHOLDER_ORDER_NOTES'})} maxLength="1000"
                        onChange={handleChange} value={formData.notes}/>
        </Form.Group>
      </Col>
    </Row>
  } else if (name1 === 'BOOKING_DISCOUNT_CODE') {
    if (!bookingInfos.discount) {
      return <></>
    }
    return <Row>
      <Col md={12}>
        <Row className="d-flex align-items-center">
          <Col className="mb-2 col-discount-1" md={8}>
            <Form.Group className="mb-2">
              <label>{intl.formatMessage({id: 'BOOKING_DISCOUNT_CODE'})}</label>
              <Form.Control
                {...(validDiscount !== undefined && validDiscount ? {disabled: true} : {})}
                {...(validDiscount !== undefined && validDiscount ? {isValid: true} : {})}
                {...(validDiscount !== undefined && !validDiscount ? {isInvalid: true} : {})}
                type="text"
                id="discountCode"
                maxLength="32"
                onChange={handleChange}
                value={formData.discountCode}
              />
              <Form.Control.Feedback type="valid">{intl.formatMessage({id: 'DISCOUNT_CODE_OK'})}</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{intl.formatMessage({id: 'DISCOUNT_CODE_FAIL'})}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="mb-2 col-discount-2" md={4}>
            {validDiscount === undefined && <label className="empty-label">&nbsp;</label>}
            <Button {...(validDiscount !== undefined && validDiscount ? {disabled: true} : {})}
                    variant="light" className="w-100 btn-apply-discount" onClick={handleApplyDiscount}>
              {intl.formatMessage({id: 'APPLY'})}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  if (isDefaultField) {
    name1 = intl.formatMessage({id: name1});
    if (name2) {
      name2 = intl.formatMessage({id: name2});
    }
  }

  return <Row>
    <Col md={name2 ? 6 : 12}>
      <Form.Group id={variable1} className="mb-2">
        <label className="wrap-label">{name1}</label>
        <Form.Control required={required1} type={type} id={generateId(isDefaultField, id, variable1)} maxLength="250"
                      onChange={handleChange} value={formData[generateId(isDefaultField, id, variable1)] || ''}/>
      </Form.Group>
    </Col>
    {name2 && <Col md={6}>
      <Form.Group id={variable2} className="mb-2">
        <label className="wrap-label">{name2}</label>
        <Form.Control required={required2} type={type} id={generateId(isDefaultField, id, variable2)} maxLength="250"
                      onChange={handleChange} value={formData[generateId(isDefaultField, id, variable2)] || ''}/>
      </Form.Group>
    </Col>}
  </Row>
});

const convertDefaultFieldNameToVariableName = (name) => {
  switch (name) {
    case 'FIRST_NAME':
      return 'firstName';
    case 'LAST_NAME':
      return 'lastName';
    case 'COMPANY_NAME':
      return 'companyName';
    case 'STREET_ADDRESS':
      return 'address';
    case 'APARTMENT_OFFICE_ETC':
      return 'additionalAddress';
    case 'CITY':
      return 'city';
    case 'FLIGHT_OR_TRAIN_NUMBER':
      return 'travelNumber';
    case 'EMAIL_ADDRESS':
      return 'email';
    case 'PHONE':
      return 'phone';
    case 'ZIP_CODE':
      return 'postalCode';
    default:
      return name;
  }
}

const generateId = (isDefaultField, id, name) => {
  return isDefaultField ? name : id + '-' + name;
}

export default FieldRow;